import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { device } from "../utils";

import CaseList from "../sections/landing1/Testimonial";
import CTA from "../sections/case-study/CTA";
// @ts-expect-error false positive image import failure
import imgBrand from "../assets/image/case-studies/southend-logo.png";
// @ts-expect-error false positive image import failure
import bgImage from "../assets/image/case-studies/Southend.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../assets/image/jpeg/case-details-half-single.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../assets/image/jpeg/case-details-half-single-2.jpg";

const Quotes = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 10px;
  color: #f04037;
`;

const Header = styled(Title)`
  font-size: 4.5rem;
`;
const Quote = styled(Text)`
  font-size: 1.5rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;
  justify-content: space-between;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #021d2d;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>Southend-on-Sea Smart City Case study</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section
          backgroundImage={`url(${bgImage})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Overlay />
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box className="text-center" mb={4}>
                  <img src={imgBrand} alt="" className="img-fluid" />
                </Box>
                <Header variant="hero">
                  Smart Radio in <br /> Southend-on-Sea
                </Header>
                <Quote>
                  <Quotes>"</Quotes>The ‘Intelligence Hub’ will constitute a
                  significant step forward - transforming how services are
                  delivered and offering potential for revenue generation and
                  trading.<Quotes>"</Quotes>
                </Quote>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row>
              <Col lg="12" xl="10" className="offset-xl-1">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    Creating a ‘Smart City’
                  </Title>
                  <Text variant="small">
                    With a clear vision within their ‘Digital Strategy
                    2017/2020’ Southend Council laid the foundation to utilise
                    technology to develop Southend as a Smart City. Key to that
                    plan was to build an Intelligence Hub, to be both the ‘eyes
                    and ears’ of the Borough and the ‘nerve centre’ through
                    which service responses, manual or automated, can be
                    triggered on a 24/7 basis building on the existing CCTV
                    facility.
                  </Text>
                </Box>
                <Box>
                  <Title variant="card" mb="18px">
                    About the Client
                  </Title>
                  <Text variant="small">
                    Southend-on-Sea lies on the north side of the Thames Estuary
                    40 miles to the east of central London. With a population of
                    180,000, Southend is the seventh most densely populated area
                    in the UK outside of the London Boroughs. The Council had
                    drawn up a wide-ranging digital strategy targeting a number
                    of areas as part of a wider aspiration to obtain ‘Smart
                    City’ accreditation.
                    <br />
                    <br />
                    “Innovation is vital to the UK’s success as a global nation,
                    and we are delighted to be playing a key role in this. We
                    want to further enable our citizens and businesses to grow
                    and evolve” said Nick Corrigan, head of digital futures at
                    Southend-on-Sea Borough Council.
                  </Text>
                </Box>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6" className="mb-lg-0 mb-30">
                <Box>
                  <img src={imgCase2} alt="" className="img-fluid" />
                </Box>
              </Col>
              <Col lg="6">
                <Box>
                  <img src={imgCase3} alt="" className="img-fluid" />
                </Box>
              </Col>
            </Row> */}
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="mt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Challenges
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small" mb={3}>
                    The Council were clear from the outset that “a local
                    Authority alone could not deliver this strategy, indeed that
                    partnerships and joint ventures with solution providers were
                    vital” and that “challenges in respect of annual budget
                    cycles and departmental silos which can make long term
                    investment difficult across areas of multiple responsibility
                    must be overcome”.
                    <br />
                    <br />
                    The Digital Strategy required the Council to adopt a
                    ‘digital-by-default’ ethos in respect of its interactions
                    with public, its internal processes and, wherever
                    practicable service delivery. In addition to the enablement
                    of infrastructure, the Council needed to create a locality
                    to which monitoring of ‘people’ and ‘place‘ related events
                    could be reported - the Southend ‘Intelligence Hub’.
                    <br />
                    <br />
                    “The creation of such a facility constitutes a significant
                    step forward in the ability of the Council to transform the
                    way in which services are delivered while, in longer term
                    offering potential for revenue generation and trading.”
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section py={1}>
          <Container>
            <Row className="mt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Solution
                  </Title>
                  <Text variant="small">
                    Included within a number of ‘proof of concept’ pilot
                    projects for the Hub in 2017/18 was Community Safety -
                    traditional CCTV services enhanced by additional
                    functionality, with IP based systems linking the location of
                    an incident and the location of those best placed to
                    respond.
                    <br />
                    <br />
                    “The remote management of events across the Borough was to
                    be achieved by the deployment of cameras, sensors or
                    assistive technology with data being relayed back to the
                    ‘Intelligence Hub’ using 3G/4G with the Intelligence Hub the
                    ‘eyes and ears’ of the Borough, the ‘nerve centre’ through
                    which service responses, manual or automated, can be
                    triggered on a 24/7 basis.”
                    <br />
                    <br />
                    Working within a detailed Service Level Agreement, ShopSafe
                    also bill the end users for the service and monitoring fees
                    – generating a revenue income stream for the Hub - whilst
                    delivering the service to the customer at a lower cost than
                    the users paid for their original ‘steam-driven’ service!
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Results
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small">
                    ShopSafe were awarded a contract late in November 2017 to
                    replace an aging shopwatch analogue radio system, with a new
                    3G/4G digital communications platform – unique to this
                    environment. Building a radio network utilising all four of
                    the major cellular networks layered together, we were able
                    to achieve levels of in-building penetration that no single
                    cell phone user can ever enjoy. With no infrastructure to
                    build, antennas to locate or networks to establish,
                    deployment was within a matter of days, well before the
                    height of Christmas trading, and strictly within the ethos
                    of the Council’s Digital Strategy.
                    <br />
                    <br />
                    Currently our ShopSafe Cellular solution has been rolled out
                    to the existing shopwatch users – the retailers, pubs and
                    clubs in Southend, plus a new extension to the scheme
                    delivered to Leigh-on-Sea working the very next day!
                    Wherever the Council wishes to operate cameras, or deliver
                    any form of monitoring or communications – this network can
                    be deployed instantly. For just a few hours for events or
                    incidents, or permanently for new areas of operation. And
                    that can be within the borough, or indeed anywhere in the
                    country – there are no boundaries. Next up we are looking to
                    other services such as parking services where we can take
                    and deliver photographs and even video; waste management;
                    traffic management; wardens; even monitoring control panels
                    or intruder alarms.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
